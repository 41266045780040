<template src="./ShowUser.html"></template>

<script>
import HallBuy from "@/components/hall/HallBuy";
import {mapGetters} from "vuex";
export default {
  name: "ShowUser",
  components: {HallBuy},
  data: () => ({


  }),

  created() {

    console.log('ea');

    if (this.$store.getters.isAuthenticated){

      // fetch(this.$store.state.auth.path+"/event/"+this.id+"", {
      //   method: 'GET',
      //   headers: {
      //     'Authorization': 'Bearer '+ this.$store.state.auth.token,
      //     'Content-Type': 'application/json'
      //   }
      // })
      //     .then(response => response.json())
      //     .then((data) => {
      //       this.event = data.data;
      //       this.event_ready = true;
      //     });

    }

  },

  computed:{
    ...mapGetters(["getEvents"]),



  },

  mounted() {

    console.log('mount');
  },



  methods:{

  }
}



</script>

<style scoped>

</style>
